html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  background-color: #ffffff;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  font-family: "Nexa", Arial, Helvetica, sans-serif;
  color: rgb(41,41,41);
  font-weight: 600;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 24px;  
}

a.link {
  text-decoration: underline;
}

h4 {
  font-family: "Nexa", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding: 8px 12px;
}

.rt-50 {
  border-top-right-radius: 50%;
}

.lb-50 {
  border-bottom-left-radius: 50%;
}

.rb-50 {
  border-bottom-right-radius: 50%;
}

.swiper-container {
  display: none;
}

@media all and (max-width: 768px) {
  h2 {
    font-size: 32px;
  }
  .swiper-container {
    display: block;
  }
}

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/open-sans-v27-latin/open-sans-v27-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/open-sans-v27-latin/open-sans-v27-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/fonts/open-sans-v27-latin/open-sans-v27-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/open-sans-v27-latin/open-sans-v27-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("/fonts/open-sans-v27-latin/open-sans-v27-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/open-sans-v27-latin/open-sans-v27-latin-regular.svg#OpenSans")
      format("svg"); /* Legacy iOS */
  font-display: block;
}

@font-face {
  font-family: "Nexa";
  font-style: bold;
  font-weight: 600;
  src: local(""), url("/fonts/Nexa-Font/NexaBold.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/Nexa-Font/NexaBold.woff") format("woff"),
    url("/fonts/Nexa-Font/NexaBold.otf") format("otf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: block;
}

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("/fonts/Nexa-Font/NexaRegular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/Nexa-Font/NexaRegular.woff") format("woff"),
    url("/fonts/Nexa-Font/NexaRegular.otf") format("otf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: block;
}


@font-face {
  font-family: "Nexa";
  font-weight: 300;
  src: local(""), url("/fonts/Nexa-Font/NexaLight.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/Nexa-Font/NexaLight.woff") format("woff"),
    url("/fonts/Nexa-Font/NexaLight.otf") format("otf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: block;
}


@media all and (max-width: 768px) {
  .mobile-pt {
    padding-top: 108px;
    padding-bottom: 32px;
  }
  .rt-50 {
    border-top-right-radius: 0;
  }

  .lb-50 {
    border-bottom-left-radius: 0;
  }

  .rb-50 {
    border-bottom-right-radius: 0;
  }
  .mobile-order-3 {
    order: 3 !important;
  }

  .mobile-hide {
    display: none;
  }
  .mobile-top-right-radius {
    border-top-right-radius: 25vw;
  }
  .mobile-top-right-radius:after {
    border-top-right-radius: 25vw;
  }
  .mobile-bottom-left-radius {
    border-bottom-left-radius: 25vw;
  }
  .mobile-bottom-left-radius:after {
    border-bottom-left-radius: 25vw;
  }
  .mobile-bg-position {
    margin-top: 32px;
  }
}
.mobile-bg-position {
  background-position-y: bottom + 1000px !important;
}
@media all and (max-width: 1080px) {
  .mobile-hide {
    display: none;
  }
  .mobile-p {
    padding: 0 32px;
  }
  .mobile-bg-position {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: bottom !important;
    margin-top: 0;
  }
  .mobile-m.mb {
    margin-bottom: 64px;
  }
  .mobile-m.mt {
    margin-top: 64px;
  }
}

.logo-footer {
  width: 64px;
  height: 12px;
  margin-top: 0px;
  margin-left: 2px;
}

.swiper-slide-active .showcase-content {
  opacity: 1;
}

.shaped-border {
  &:before {
    content: "";
    position: absolute;
    top: -40px;
    left: 40px;
    height: 40px;
    width: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: 0 24px 0 0 #7459e7;
    z-index: 1;
  }

    &:before {
    content: "";
    position: absolute;
    top: -40px;
    left: 40px;
    height: 40px;
    width: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: 0 24px 0 0 #7459e7;
    z-index: 1;
  }
}